import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import IndexPage from './components/IndexPage';
import Login from './components/LoginPage';
import SignUp from './components/SignupPage';
import Dashboard from './components/Dashboard';
import AboutUs from './components/AboutUs.jsx'
import Sidebar from './components/SideBar';
import ContactUs from './components/ContactUs.jsx';
import LocalTransferForm from './components/LocalTransfer';
import SettingsPage from './components/Settings';
import ResetPassword from './components/ResetPassword';
import Transactions from './components/Transactions';
import DepositPage from './components/DepositePage.jsx';
import Admin from './components/Admin.jsx';
import EditUser from './components/EditUser.jsx';
import ViewUser from './components/ViewUsers.jsx';
import SafeDeposit from './components/safeDeposit.jsx';
import Rental from './components/Rental.jsx';
import BankServices from './components/BankServices.jsx';
import UsefulInfo from './components/UsefulInfoPage.jsx';
import EmailSender from './components/EmailSender.jsx';
function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
    <Router scrollRestoration="manual">
        
        <Sidebar />
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="/send-email" element={<EmailSender />} />
          <Route path="/admin/edit/:userId" element={<EditUser />} />
          <Route path='/admin/view/users/:userId' element={<ViewUser/>} />
          <Route path="/" element={<IndexPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/deposit" element={<DepositPage />} />
          <Route path="/transfer" element={<LocalTransferForm />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/contactUs' element={<ContactUs/>}/>
          <Route path='/deposit/services' element={<SafeDeposit/>}/>
          <Route path="/signup" element={<SignUp />} />
          <Route path='/useful/information' element={<UsefulInfo/>}/>
          <Route path='/bank/services' element={<BankServices/>}/>
          <Route path='/rental/services' element={<Rental/>}/>
        </Routes>
        </Router>
      </div>
      </I18nextProvider>
  );
}

export default App;