import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Admin.css';
import { Loader } from 'lucide-react';

const Admin = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        country: '',
        state: '',
        city: '',
        phoneNumber: '',
        email: '',
        password: '',
    });
    const [error, setError] = useState('');

    useEffect(() => {
        //    checkAdmin()
        checkAuthStatus();

    }, []);

    const checkAuthStatus = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('token');

        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${global.baseUrl}users/auth/check`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.status === 200) {
                throw new Error('Token validation failed');
            }

            const data = await response.json();
            if (data.message === 'Token is valid') {
                await fetchUsers(token);
                checkAdmin()
            } else {
                throw new Error('Invalid token');
            }
        } catch (error) {
            console.error('Authentication error:', error);
            navigate('/login');
        }
    };
    const checkAdmin = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('token');

        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${global.baseUrl}users/admin/check`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Token validation failed');
            }

            const data = await response.json();
            if (data.message === 'true') {
                await fetchUsers(token);
            } else {
                throw new Error('Access Denied');
            }
        } catch (error) {
            console.error('Authentication error:', error);
            alert("Access Denied")
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUsers = async (token) => {
        try {
            const response = await fetch(`${global.baseUrl}users/allUsers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }

            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const toggleDropdown = (userId) => {
        setActiveDropdown(activeDropdown === userId ? null : userId);
    };

    const handleView = (userId) => {
        navigate(`/admin/view/users/${userId}`);
    };

    const handleEdit = (userId) => {
        navigate(`/admin/edit/${userId}`);
    };



    const handleDelete = async (userId) => {
        console.log(`Deleting user with ID ${userId}`);
        const token = localStorage.getItem('token');


        try {
            const response = await axios.delete(`${global.baseUrl}users/deleteAccount/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                alert(`User deleted successfully: ${response.data.message}`);
                window.location.reload()
            } else {
                alert("Unable to delete, please try again")
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            alert(`Error deleting user: ${error.response ? error.response.data.message : error.message}`);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleAddUser = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${global.baseUrl}users/addUser`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues),
            });

            const data = await response.json();

            if (!response.ok) {
                window.alert(data.message)
                throw new Error(data.message || 'Failed to add user');
            } else {
                window.alert(data.message)
                window.location.reload();
            }

            setUsers([...users, data]);
            setShowAddUserModal(false);
            setFormValues({
                firstName: '',
                lastName: '',
                country: '',
                state: '',
                city: '',
                phoneNumber: '',
                email: '',
                password: '',
            });
        } catch (error) {
            console.error('Error adding user:', error);
            setError(error.message);
        }
    };

    if (isLoading) {
        return (
            <div className="overlay">
                <Loader className="spinner" />
                <p>Checking authentication...</p>
            </div>
        );
    }

    return (
        <div className='AdminMainCont'>
            <h1 className='adminH1'>Welcome!</h1>
            <button className='adminAddUser' onClick={() => setShowAddUserModal(true)}>Add New User</button>
            <div className='tableSection'>
                <table className='user-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Balance</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id} className='user-row'>
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.email}</td>
                                <td>{`$${user.balance}`}</td>
                                <td>
                                    <div className='dropdown'>
                                        <button onClick={() => toggleDropdown(user.id)}>Actions</button>
                                        {activeDropdown === user.id && (
                                            <div className='dropdown-content'>
                                                <button onClick={() => handleView(user.id)}>View</button>
                                                <button onClick={() => handleEdit(user.id)}>Edit</button>
                                                <button onClick={() => handleDelete(user.id)}>Delete</button>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showAddUserModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Add New User</h2>
                        {error && <p className="error">{error}</p>}
                        <form>
                            <input
                                type="text"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleInputChange}
                                placeholder="First Name"
                            />
                            <input
                                type="text"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleInputChange}
                                placeholder="Last Name"
                            />
                            <input
                                type="text"
                                name="country"
                                value={formValues.country}
                                onChange={handleInputChange}
                                placeholder="Country"
                            />
                            <input
                                type="text"
                                name="state"
                                value={formValues.state}
                                onChange={handleInputChange}
                                placeholder="State"
                            />
                            <input
                                type="text"
                                name="city"
                                value={formValues.city}
                                onChange={handleInputChange}
                                placeholder="City"
                            />
                            <input
                                type="text"
                                name="phoneNumber"
                                value={formValues.phoneNumber}
                                onChange={handleInputChange}
                                placeholder="Phone Number"
                            />
                            <input
                                type="email"
                                name="email"
                                value={formValues.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                            />
                            <input
                                type="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleInputChange}
                                placeholder="Password"
                            />
                            <button type="button" onClick={handleAddUser}>Add User</button>
                            <button type="button" onClick={() => setShowAddUserModal(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Admin;
