import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/ContactUs.css';
import bannerImg from '../images/contactUsImg.jpg';
import complaintPDF from '../images/Complaint_-_notice_to_customer_20240328.pdf';
import TopNav from './TopNav';
import Footer from './Footer';

const ContactUs = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleComplaintClick = () => {
    window.open(complaintPDF, '_blank');
  };

  const Section = ({ id, title, children }) => (
    <div id={id} className="section">
      <div className="section-header">{t(title)}</div>
      <div className="section-body">{children}</div>
    </div>
  );

  const FlexContainer = ({ leftContent, rightContent }) => (
    <div className="flex-container">
      <div className="flex-item titles">{leftContent}</div>
      <div className="flex-item content">{rightContent}</div>
    </div>
  );

  return (
    <div className="contactMainCont">
        <br />
      <TopNav />
      <br />
      <div className='banner'>
        <img id='bannerImg' src={bannerImg} alt='' />
      </div>
      
      <div className="container">
        <Section id="banking-hours" title="Banking Hours">
          <FlexContainer
            leftContent={
              <>
                <div>{t('Operating Hours')}:</div>
                <div>{t('Cut-off Time for Cash Transactions*')}</div>
                <div>{t('Cut-off Time for Cheque Deposits')}</div>
              </>
            }
            rightContent={
              <>
                <div>{t('Monday to Friday: 9:00am to 5:00pm Saturday: 9:00am to 1:00pm')}</div>
                <div>{t('Monday to Friday: 4:30pm Saturday: 12:30pm')}</div>
                <div>{t('Cut-off Time for Cheque Deposits')}</div>
              </>
            }
          />
          <div className="note">
            {t('* Cash deposits and withdrawals after the cut-off time will not be accepted')}<br />
            {t('** Cheques deposited after the cut-off time will be valued the next clearing day')}
          </div>
        </Section>

        <Section id="general-enquiries" title="General Enquiries">
          <FlexContainer
            leftContent={
              <>
                <div>{t('Address')}</div>
                <div>{t('Email')}</div>
                <div>{t('Fax Number')}</div>
                <div>{t('Fax Number Operations')}</div>
                <div>{t('SWIFT Code')}</div>
              </>
            }
            rightContent={
              <>
                <div>{t('130-132 Des Voeux Road Central, Hong Kong')}</div>
                <div>info@taisbkhk.com</div>
                <div>2545 5242</div>
                <div>2853 0812</div>
                <div>TSBLHKHH</div>
              </>
            }
          />
        </Section>

        <Section id="customer-complaint" title="Customer Complaint">
          <FlexContainer
            leftContent={
              <>
                <div>{t('Correspondence Address')}</div>
              <br />
              <br />
                <div>{t('Email')}</div>
              </>
            }
            rightContent={
              <>
                <div>{t('Complaint Officer, Tai Sang Bank Limited, 3/F Tai Sang Bank Building, 130-132 Des Voeux Road Central, Hong Kong')}</div>
                <br />
                <div>opinion@taisbkhk.com</div>
              </>
            }
          />
          <button onClick={handleComplaintClick}>{t('Customer Complaint')}</button>
        </Section>

        <Section id="barrier-free-access" title="Barrier-free Access Measures">
          <ul>
            <li>{t('1. Our bank lobby can be reached by temporary ramp for wheelchair access.')}</li>
            <li>{t('2. Amplifier Microphone Speaker System is available at bank lobby for providing services to hearing-impaired customers')}</li>
            <li>{t('3. Guide dogs are welcome')}</li>
          </ul>
        </Section>
      </div>

      <Footer/>
    </div>
  );
}

export default ContactUs;