import { useNavigate } from 'react-router-dom';
import React, {  useEffect } from 'react';

const authMiddleware = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const checkAuthStatus = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${global.baseUrl}users/auth/check`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.status === 200) {
          throw new Error('Token validation failed');
        }

        const data = await response.json();
        if (data.message === 'Token is valid') {
          // Call any other necessary functions, like fetchUsers and checkAdmin
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        localStorage.removeItem('token')
        navigate('/login');
      }
    };

    useEffect(() => {
      checkAuthStatus();
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default authMiddleware;