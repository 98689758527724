import React, { useState, useEffect } from 'react';
import countriesData from '../countries.json'
import statesData from '../states+cities.json'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';

import { Modal, Button, Form } from "react-bootstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha
} from "react-simple-captcha";
import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/SignUp.css';
import axios from 'axios';

const SignUp = () => {
  const { t, i18n } = useTranslation();
  //const navigate = useNavigate();
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [cities, setCities] = useState([]);
  const [isResending, setIsResending] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    country: '',
    username: '',
    state: '',
    city: '',
    companyName: '',
    address: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
    typeOfEmployment: 'fullTime',
    annualIncomeRange: 'lessThan10k',
    SSN_TIN: '',
    accountType: '',
    accountCurrency: '',
    twoFactorPIN: '',
    password: '',
    sendOtp: true,
    profilePhoto: null,
  });



  useEffect(() => {
    if (formData.country) {
      const selectedStates = statesData.filter(state => state.country_id.toString() === formData.country);
      setStates(selectedStates);
      setFormData(prev => ({ ...prev, state: '', city: '' }));
    }
  }, [formData.country]);
  useEffect(() => {
    if (formData.state) {
      const selectedState = states.find(state => state.id.toString() === formData.state);
      setCities(selectedState ? selectedState.cities : []);
      setFormData(prev => ({ ...prev, city: '' }));
    }
  }, [formData.state, states]);
  const handleResendOtp = async () => {
    setIsResending(true);
    try {
      //  const response = await axios.post(`http://localhost:3002/users/resend-otp`, { email: registeredEmail });
      const response = await axios.post(`${global.baseUrl}users/resend-otp`, { email: registeredEmail });

      if (response.status === 200) {
        alert('OTP has been resent to your email.');
      } else {
        throw new Error('Failed to resend OTP');
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      alert('Failed to resend OTP. Please try again.');
    } finally {
      setIsResending(false);
    }
  };


  useEffect(() => {
    loadCaptchaEnginge(5);
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profilePicture: e.target.files[0],
    });
  };

  const handleDateChange = (date) => {
    handleChange({
      target: {
        name: 'dateOfBirth',
        value: date
      }
    });
  };
  const handleReset = () => {
    setFormData({
      firstName: '',
      middleName: '',
      lastName: '',
      country: '',
      state: '',
      dateOfBirth: '',
      city: '',
      companyName: '',
      phoneNumber: '',
      address: '',
      email: '',
      typeOfEmployment: 'fullTime',
      annualIncomeRange: 'lessThan10k',
      SSN_TIN: '',
      accountType: '',
      accountCurrency: '',
      twoFactorPIN: '',
      sendOtp: true,
      password: '',
      profilePhoto: null,
    });
  };

  useEffect(() => {
    // loadCaptchaEnginge(2);
    loadCaptchaEnginge(5);
  }, []);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleCaptchaSubmit = () => {
    let userCaptcha = document.getElementById("user_captcha_input").value;
    if (validateCaptcha(userCaptcha)) {
      alert("Verified!");
      setShowCaptcha(false);
      setShowTerms(true);
    } else {
      alert("Invalid Captcha, please try again.");
      document.getElementById("user_captcha_input").value = "";
    }
  };
  const handleAcceptTerms = () => {
    setShowTerms(false);
    setShowSignUp(true);
  };

  const handleDeclineTerms = () => {
    alert(t('signup.mustAcceptTerms'));
  };

  const termsAndConditions = `
    Terms and Conditions of Tai Sang Bank

    Introduction
    These terms and conditions govern your use of the services provided by Tai Sang Bank (hereinafter referred to as "the Bank"). By accessing or using our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not use our services.

    History and Establishment
    Tai Sang Bank was founded in 1937 by Ma Kam Chan and is one of the oldest banks in Hong Kong. It is part of the Tai Sang group, which is listed on the Hong Kong Stock Exchange.

    Services Offered
    The Bank offers a range of banking services, including but not limited to:
    a) Account Opening: You may open various types of accounts with the Bank, subject to the Bank's account opening requirements and procedures.
    b) Deposits: You may deposit funds into your account(s) with the Bank in accordance with the Bank's deposit policies.
    c) Withdrawals: You may withdraw funds from your account(s) subject to the available balance and withdrawal limits set by the Bank.
    d) Foreign Exchange: The Bank provides foreign exchange services for eligible transactions, subject to prevailing exchange rates and applicable regulations.
    e) Remittances: You may request inward or outward remittances through the Bank, subject to applicable fees and regulatory requirements.
    f) Loans and Credit Facilities: The Bank may provide loans or credit facilities to eligible customers based on their creditworthiness and compliance with the Bank's lending policies.

    Branch Operations
    The Bank operates from a single branch located on Des Voeux Road Central in Hong Kong. Our operating hours are subject to change without prior notice. Please refer to our website or contact our branch for current opening hours.

    Online Services
    Please note that Tai Sang Bank does not offer online banking or ATM services at present. All banking transactions must be conducted at our branch.

    Personalized Customer Service
    At Tai Sang Bank, we emphasize personalized customer service. Our dedicated team of professionals is committed to providing you with the highest level of service and assistance. We strive to understand your unique financial needs and tailor our services accordingly.

    Clientele
    Tai Sang Bank primarily serves brokers and gold traders. However, we welcome customers from various industries and backgrounds who meet our account opening requirements.

    Intellectual Property
    All intellectual property rights, including but not limited to trademarks, logos, and content displayed on our website or other communication channels, belong to Tai Sang Bank or its licensors. You may not use, reproduce, or distribute any of these materials without prior written consent from the Bank.

    Governing Law and Jurisdiction
    These terms and conditions shall be governed by and construed in accordance with the laws of Hong Kong Special Administrative Region ("HKSAR"). Any disputes arising out of or in connection with these terms and conditions shall be submitted to the exclusive jurisdiction of the courts of HKSAR.

    Amendments
    The Bank reserves the right to modify or amend these terms and conditions at any time without prior notice. Any such changes will be effective immediately upon posting on our website or other communication channels. It is your responsibility to regularly review these terms and conditions for updates.

    Severability
    If any provision of these terms and conditions is deemed invalid, illegal, or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.

    Contact Information
    For any inquiries or assistance regarding our services or these terms and conditions, please contact us at:

    Tai Sang Bank 123 Des Voeux Road Central Hong Kong Email: info@taisbkhk.com

    By using Tai Sang Bank's services, you acknowledge that you have read, understood, and agreed to these terms and conditions.
  `;
  const truncatedText = termsAndConditions.substring(0, 300);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Use countriesData to find the selected country, state, and city names
    const selectedCountryName = countriesData.find(country => country.id.toString() === formData.country)?.name || '';
    const selectedStateName = states.find(state => state.id.toString() === formData.state)?.name || '';
    const selectedCityName = cities.find(city => city.id.toString() === formData.city)?.name || '';

    // Create a new form data object
    const formDataToSend = new FormData();

    // Append only necessary fields, including names of country, state, and city
    Object.keys(formData).forEach((key) => {
      if (key === 'country' || key === 'state' || key === 'city') {
        return; // Skip appending these directly
      }
      formDataToSend.append(key, formData[key]);
    });

    // Append the names explicitly
    formDataToSend.append('country', selectedCountryName);
    formDataToSend.append('state', selectedStateName);
    formDataToSend.append('city', selectedCityName);

    try {
      const response = await axios.post(`${global.baseUrl}users/signup`, formDataToSend);
      const data = await response.data;

      // localStorage.setItem('token', data.token);
      // localStorage.setItem('user', JSON.stringify(data.user));

      console.log('User created successfully:', data);

      if (!data.user.email_verified) {
        setShowOtpModal(true); // Show OTP modal
        setRegisteredEmail(data.user.email); // Save the registered email
      } else {
        navigate('/dashboard'); // Redirect to the dashboard or any other page
      }
    } catch (error) {
      console.error('There was an error!', error.response.data);
      alert(error.response.data.message);
    }
  };
  const fetchCountries = async () => {
    try {
      const response = await fetch('https://sandbox.carrotsuite.co/api/v1/countries');
      const data = await response.json();
      if (data.status === 'success') {
        setCountries(data.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  useEffect(() => {
    fetchCountries();
  }, []);


  // const handleVerifyOtp = async () => {
  //   try {
  //     const response = await fetch(`${global.baseUrl}users/verify-otp`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ email: registeredEmail, otp }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('OTP verification failed');
  //     }

  //     const data = await response.json();
  //     alert('OTP verified successfully');
  //     setShowOtpModal(false); // Hide OTP modal
  //     navigate('/dashboard'); // Redirect to the dashboard or any other page

  //   } catch (error) {
  //     console.error('There was an error verifying the OTP!', error);
  //     alert('There was an error verifying the OTP');
  //   }
  // };
  const handleVerifyOtp = async () => {
    try {
      const response = await fetch(`${global.baseUrl}users/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: registeredEmail, otp }),
      });

      if (!response.ok) {
        throw new Error('OTP verification failed');
      }
      alert('Account created successfully');

      const data = await response.json();
      setShowOtpModal(false);
      setShowThankYouModal(true); // Show the Thank You modal instead of navigating directly
    } catch (error) {
      console.error('There was an error verifying the OTP!', error);
      alert('There was an error verifying the OTP');
    }
  };
  const handleThankYouOk = () => {
    setShowThankYouModal(false);
    navigate('/'); // Navigate to the home page
  };
  return (
    <div className="container">
      <Modal show={showCaptcha} onHide={() => { }}>
        <Modal.Header>
          <Modal.Title>Captcha Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadCanvasTemplate />
          <input type="text" id="user_captcha_input" name="user_captcha_input" />
        </Modal.Body>
        <Modal.Footer>



          <Button variant="primary" onClick={handleCaptchaSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTerms} onHide={() => { }}>
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showFullText ? termsAndConditions : `${truncatedText}...`}
          <Button variant="link" onClick={() => setShowFullText(!showFullText)}>
            {showFullText ? "Show Less" : "Show More"}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeclineTerms}>
            Decline
          </Button>
          <Button variant="primary" onClick={handleAcceptTerms}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="otp">
            <Form.Label>Enter the OTP sent to your email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOtpModal(false)}>
            Close
          </Button>
          <Button
            variant="link"
            onClick={handleResendOtp}
            disabled={isResending}
          >
            {isResending ? 'Resending...' : 'Resend OTP'}
          </Button>
          <Button variant="primary" onClick={handleVerifyOtp}>
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showThankYouModal} onHide={() => setShowThankYouModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Application Has Been Submitted.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleThankYouOk}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      {showSignUp && (
        <div className="signup-container">
          <h2 className="signup-title">Sign Up Form</h2>
          <p className="signup-description">Please provide the information requested below to create your account.</p>
          <hr className="signup-divider" />

          <Form onSubmit={handleSubmit} className="signup-form">
            <div className="form-row">
              <Form.Group controlId="firstName">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="middleName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter middle name"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label>Last Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="username">
                <Form.Label>User Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user name"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="companyName">
                <Form.Label>Company Name </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  // required
                />
              </Form.Group>
            </div>

            <div className="form-row">
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Select a country</option>
                  {countriesData.map(country => (
                    <option key={country.name} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  disabled={!formData.country}
                >
                  <option value="">Select a state</option>
                  {states.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>





              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  as="select"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  disabled={!formData.state}
                >
                  <option value="">Select a city</option>
                  {cities.map(city => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>

            <div className="form-row">
              <Form.Group controlId="dob">
                <Form.Label>Date of Birth *</Form.Label>
                <DatePicker
                  onChange={handleDateChange}
                  value={formData.dateOfBirth}
                  style={{ width: '100%' }}
                  required
                />
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Address *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number *</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>

            <div className="form-row">
              <Form.Group controlId="email">
                <Form.Label>Email address *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="typeOfEmployment">
                <Form.Label>Type of Employment *</Form.Label>
                <Form.Control
                  as="select"
                  name="typeOfEmployment"
                  value={formData.typeOfEmployment}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select employment type</option>
                  <option value="fullTime">Full Time</option>
                  <option value="partTime">Part Time</option>
                  <option value="contract">Contract</option>
                  <option value="freelance">Freelance</option>
                  <option value="selfEmployed">Self Employed</option>
                  <option value="student">Student</option>
                  <option value="retired">Retired</option>
                  <option value="unemployed">Unemployed</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="annualIncomeRange">
                <Form.Label>Annual Income Range *</Form.Label>
                <Form.Control
                  as="select"
                  name="annualIncomeRange"
                  value={formData.annualIncomeRange}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select income range</option>
                  <option value="lessThan10k">Less than $10,000</option>
                  <option value="10kTo50k">$10,000 - $50,000</option>
                  <option value="50kTo100k">$50,000 - $100,000</option>
                  <option value="100kTo500k">$100,000 - $500,000</option>
                  <option value="moreThan500k">More than $500,000</option>
                </Form.Control>
              </Form.Group>
    
              <Form.Group controlId="accountCurrency">
                <Form.Label>Currency *</Form.Label>
                <Form.Control
                  as="select"
                  name="accountCurrency"
                  value={formData.accountCurrency}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select currency</option>
                  <option value="USD">$ - US Dollar</option>
                  <option value="EUR">€ - Euro</option>
                  <option value="GBP">£ - British Pound</option>
                  <option value="HKD">HK$ - Hong Kong Dollar</option>
                </Form.Control>
              </Form.Group>
            </div>

            <div className="form-row">
              <Form.Group controlId="SSN_TIN">
                <Form.Label>SSN or TIN *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter SSN or TIN"
                  name="SSN_TIN"
                  value={formData.SSN_TIN}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="accountType">
                <Form.Label>Account Type *</Form.Label>
                <Form.Control
                  as="select"
                  name="accountType"
                  value={formData.accountType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select account type</option>
                  <option value="savings">Savings</option>
                  <option value="creditLine">Credit Line</option>
                  <option value="current">Current</option>
                </Form.Control>
              </Form.Group>

              
            </div>

            <div className="form-row">
         
              <Form.Group controlId="profilePhoto">
                <Form.Label>Upload Profile Photo</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </div>

            <div className="form-buttons">
              <Button className="submit-btn" variant="primary" type="submit">
                Submit
              </Button>
              <Button className="reset-btn" variant="secondary" type="button" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="link" onClick={handleBack}>
                Back
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default SignUp;