import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <img
            className="footer-logo"
            onClick={navigateToHome}
            id="logo"
            src={logo}
            alt="logo"
          />
          <p className="footer-copyright">
            {t('allRightsReserved')} © 2024 Tai Sang Bank Limited
          </p>
        </div>
        <div className="footer-section">
          <h4 className="footer-heading">{t('contactUs')}</h4>
          <p>{t('contactUsAddress')}</p>
          <p>{t('contactUsEmail')}</p>
          <p>(852) 9039 4487</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;