import React, {  useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/safeDeposit.css';
import bannerImg from '../images/safeBoxImg.png';
import TopNav from './TopNav';

const SafeDeposit = () => {
    
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

 

    return (
        <div>
            <br />
            <TopNav />
            <br />
            <div className='bannerImg'>
                <img id='bannerImg' src={bannerImg} alt="" />
            </div><br />

            <div className='safeContent'>
                <div className='central'>
                    <h2>{t('central')}</h2>

                    <div className='safeContentHeader'>
                        <h4>{t('address')}</h4>
                    </div>
                    <div className='safeContentBody'>
                        <span>{t('centralAddress')}</span>
                    </div><br /><br />
                    <div className='safeContentHeader'>
                        <h4>{t('contactNoEnquiryHotline')}</h4>
                    </div>
                    <div className='safeContentBody'>
                        <span>{t('centralContactNo')}</span>
                    </div><br /><br />
                    <div className='safeContentHeader'>
                        <h4>{t('operatingTimes')}</h4>
                    </div>
                    <div className='safeContentBodyj'>
                        <span>{t('centralOperatingTimesWeekday')}</span><br />
                        <span>{t('centralOperatingTimesSaturday')}</span>
                    </div><br /><br />
                    <div className='safeContentHeader'>
                        <h4>{t('safeDepositBoxDimensions')}</h4>
                    </div>
                    <div className='safeContentBodyi'>
                        <span id='largeSpan'>{t('centralBoxDescription1')}</span><br />
                        <span id='largeSpan'>{t('centralBoxDescription2')}</span><br />
                        <span id='largeSpan'>{t('centralBoxDescription3')}</span><br />
                        <span id='largeSpan'>{t('centralBoxDescription4')}</span>
                        <br />
                        <div className='sdbdheader'>
                         <span>{t('boxType')}</span>	<span>{t('dimensions')}</span>
                        </div>
                        {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'].map((type, index) => (
                            <div key={type} className='sdbdBody' id={index % 2 === 0 ? 'ash' : ''}>
                                <p>{type} <span id='sdbdSpan'>{t('boxDimensions')}</span></p>
                            </div>
                        ))}
                        <br />
                        <span>{t('dimensionsDisclaimer1')}</span><br />
                        <span>{t('dimensionsDisclaimer2')}</span><br /><br />
                        <span>{t('centralEnquiry')}</span>
                    </div><br /><br />
                </div>

                <div>
                    <h2>{t('quarryBay')}</h2>
                    <div className='safeContentHeader'>
                        <h4>{t('address')}</h4>
                    </div>
                    <div className='safeContentBody'>
                        <span>{t('quarryBayAddress')}</span>
                    </div><br /><br />
                    <div className='safeContentHeader'>
                        <h4>{t('contactNoEnquiryHotline')}</h4>
                    </div>
                    <div className='safeContentBody'>
                        <span>{t('quarryBayContactNo')}</span>
                    </div><br /><br />
                    <div className='safeContentHeader'>
                        <h4>{t('operatingTimes')}</h4>
                    </div>
                    <div className='safeContentBodyj'>
                        <span>{t('quarryBayOperatingTimes1')}</span><br />
                        <span>{t('quarryBayOperatingTimes2')}</span><br />
                        <span>{t('quarryBayOperatingTimes3')}</span><br />
                        <span>{t('quarryBayOperatingTimes4')}</span>
                    </div><br /><br />
                    <div className='safeContentHeader'>
                        <h4>{t('safeDepositBoxDimensions')}</h4>
                    </div>
                    <div className='safeContentBodyi'>
                        <span id='largeSpan'>{t('quarryBayBoxDescription1')}</span><br />
                        <span id='largeSpan'>{t('quarryBayBoxDescription2')}</span>
                        <br />
                        <div className='sdbdheader'>
                         <span>{t('boxType')}</span>	<span>{t('dimensions')}</span>
                        </div>
                        {['1', '2', '3', '4', '5', '6', '7'].map((type, index) => (
                            <div key={type} className='sdbdBody' id={index % 2 === 0 ? 'ash' : ''}>
                                <p>{type} <span id='sdbdSpan'>{t('boxDimensions')}</span></p>
                            </div>
                        ))}
                        <br />
                        <span>{t('dimensionsDisclaimer1')}</span><br />
                        <span>{t('dimensionsDisclaimer2')}</span><br /><br />
                        <span>{t('quarryBayEnquiry')}</span>
                    </div><br /><br />
                </div>
            </div>
        </div>
    );
};

export default SafeDeposit;