import authMiddleware from './authMiddleware';
import { FaUserCircle, FaLock, FaSave } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import axios from 'axios';
import '../styles/settings.css';
import { DatePicker } from 'antd';
import moment from 'moment';
const SettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    accountNumber: '',
    dateOfBirth: '',
    address: '',
    phone: '',
  });
  const [profilePic, setProfilePic] = useState('/api/placeholder/100/100');
  const [showFileInput, setShowFileInput] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [isProfilePicEnlarged, setIsProfilePicEnlarged] = useState(false);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserInfo({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        address: user.address,
        phone: user.phoneNumber,
      });
      if (user.profilePic) {
        fetchProfilePicture(user.profilePic);
      }
    }
    fetchProfilePictureFromServer();
  }, []);

  const fetchProfilePicture = (profilePicUrl) => {
    setProfilePic(profilePicUrl);
  };

  const fetchProfilePictureFromServer = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${global.baseUrl}users/profile-picture`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      });

      const base64String = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      setProfilePic(`data:${response.headers['content-type'].toLowerCase()};base64,${base64String}`);
    } catch (error) {
      console.error(t('errorFetchingProfilePic'), error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResetPassword = () => {
    navigate('/reset-password');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(t('savingEdits'), userInfo);

    try {
      const formData = new FormData();
      formData.append('firstName', userInfo.firstName);
      formData.append('lastName', userInfo.lastName);
      formData.append('email', userInfo.email);
      formData.append('dateOfBirth', userInfo.dateOfBirth);
      formData.append('address', userInfo.address);
      formData.append('phone', userInfo.phone);
      if (profilePicFile) {
        formData.append('profilePicture', profilePicFile);
      }

      const token = localStorage.getItem('token');
      const response = await axios.put(`${global.baseUrl}users/editProfile`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(t('profileUpdated'), response.data);

      localStorage.setItem('user', JSON.stringify(response.data.user));

      setIsSuccessAlertVisible(true);

      setTimeout(() => {
        setIsSuccessAlertVisible(false);
      }, 5000);
    } catch (error) {
      console.error(t('errorUpdatingProfile'), error);
    }
  };

  const toggleProfilePicEnlarged = () => {
    setIsProfilePicEnlarged(!isProfilePicEnlarged);
  };
  const handleDateChange = (date, dateString) => {
    handleChange({
      target: {
        name: 'dateOfBirth',
        value: dateString,
      },
    });
  };
  console.log(moment(userInfo.dateOfBirth))
  console.log(userInfo)
  return (
    <div className="settings-container">
      <div className="settings-header">
        <h1>{t('settings')}</h1>
      </div>

      {isSuccessAlertVisible && (
        <div className="success-alert">
          {t('profileUpdatedSuccessfully')}
        </div>
      )}

      <div className="settings-content">
        <div className="profile-section">
          <div className="profile-pic-container">
            <img
              src={profilePic}
              alt={t('profile')}
              className="profile-pic"
              onClick={toggleProfilePicEnlarged}
            />
            {isProfilePicEnlarged && (
              <div className="profile-pic-overlay" onClick={toggleProfilePicEnlarged}>
                <img src={profilePic} alt={t('enlargedProfile')} className="enlarged-profile-pic" />
              </div>
            )}
          </div>
          <button
            className="change-pic-button"
            onClick={() => setShowFileInput(!showFileInput)}
          >
            <FaUserCircle /> {t('changeProfilePicture')}
          </button>
          {showFileInput && (
            <div className="file-input-container">
              <input
                key={fileInputKey}
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                className="file-input"
              />
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  setProfilePicFile(null);
                  setProfilePic('/api/placeholder/100/100');
                  setFileInputKey(Date.now());
                  setShowFileInput(false);
                }}
              >
                {t('cancel')}
              </button>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="settings-form">
          <div className="form-grid">
            <div className="form-group">
              <label>{t('dateOfBirth')}</label>
              <DatePicker
                name="dateOfBirth"
                value={userInfo.dateOfBirth ? moment(userInfo.dateOfBirth) : null}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                className="form-input"
              />
            </div>
            <div className="form-group">
              <label>{t('address')}</label>
              <input
                type="text"
                name="address"
                value={userInfo.address}
                onChange={handleChange}
                className="form-input"
              />
            </div>
            <div className="form-group">
              <label>{t('phone')}</label>
              <input
                type="tel"
                name="phone"
                value={userInfo.phone}
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>

          <div className="security-section">
            <h2>{t('security')}</h2>
            <button onClick={handleResetPassword} type="button" className="security-button">
              <FaLock /> {t('resetPassword')}
            </button>
          </div>

          <div className="form-actions">
            <button type="submit" className="save-button">
              <FaSave /> {t('saveEdits')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default authMiddleware(SettingsPage)