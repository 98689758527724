import authMiddleware from './authMiddleware';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaFilter } from 'react-icons/fa';
import { useCurrencyFormatter } from './currencyFormatter'; // Assuming the file is in the same folder
import '../styles/Transactions.css';

const Transactions = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('All');
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState('');
  const { formatCurrency, error: currencyError, refetchUserData } = useCurrencyFormatter();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${global.baseUrl}users/transactions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || t('fetchTransactionsError'));
        }
        setTransactions(data.transactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError(error.message || t('genericError'));
      }
    };

    fetchTransactions();
  }, [t]);

  const filteredTransactions = transactions.filter(transaction => 
    (filterType === 'All' || transaction.type === filterType) &&
    (transaction.reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
     transaction.amount.toString().includes(searchTerm))
  );

  if (currencyError) {
    return <div>Error: {currencyError}. <button onClick={refetchUserData}>Retry</button></div>;
  }

  return (
    <div className="transactions-page">
      <header className="transactions-header">
        <h1>{t('transactions')}</h1>
        <div className="search-filter">
          <div className="search-bar">
            <input
              type="text"
              placeholder={t('searchPlaceholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="search-icon" />
          </div>
          <div className="filter-dropdown">
            <select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
              <option value="All">{t('allTypes')}</option>
              <option value="Deposit">{t('deposit')}</option>
              <option value="Withdrawal">{t('withdrawal')}</option>
              <option value="Transfer">{t('transfer')}</option>
            </select>
            <FaFilter className="filter-icon" />
          </div>
        </div>
      </header>

      <section className="transactions-list">
        {error && <p className="error-message">{error}</p>}
        <table>
          <thead>
            <tr>
              <th>{t('amount')}</th>
              <th>{t('type')}</th>
              <th>{t('referenceId')}</th>
              <th>{t('status')}</th>
              <th>{t('date')}</th>
              <th>{t('time')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index}>
                            <td>{formatCurrency(parseFloat(transaction.amount))}</td>

                
                <td>{t(transaction.type.toLowerCase())}</td>
                <td>{transaction.reference}</td>
                <td>
                  <span className={`status ${transaction.status.toLowerCase()}`}>
                    {t(transaction.status.toLowerCase())}
                  </span>
                </td>
                <td>{new Date(transaction.dateTime).toLocaleDateString()}</td>
                <td>{new Date(transaction.dateTime).toLocaleTimeString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default authMiddleware(Transactions)