import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';

const EmailSender = () => {
  const [formData, setFormData] = useState({
    email: '',
    sender: 'kuen@taisbkhk.com', // Default value
    title: '',
    body: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });

  const senderOptions = [
    'kuen@taisbkhk.com',
    'info@taisbkhk.com',
    'contact@taisbkhk.com'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ type: '', message: '' });

    try {
      const response = await fetch(`${global.baseUrl}users/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      const data = await response.json();
      setStatus({ type: 'success', message: data.message });
      setFormData({ email: '', sender: 'kuen@taisbkhk.com', title: '', body: '' });
    } catch (error) {
      setStatus({ type: 'error', message: error.message });
    }
  };

  return (
    <div className="email-sender">
      <h2>Send Email</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Recipient Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="sender">Sender Email</label>
          <select
            id="sender"
            name="sender"
            value={formData.sender}
            onChange={handleChange}
            required
          >
            {senderOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="title">Email Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="body">Email Body</label>
          <textarea
            id="body"
            name="body"
            value={formData.body}
            onChange={handleChange}
            required
            rows="4"
          ></textarea>
        </div>
        <button type="submit">Send Email</button>
        <br />
        <br />
        <button className='bbackButton' onClick={handleBack}>Back</button>
        
      </form>
      {status.message && (
        <div className={`alert ${status.type}`}>
          {status.message}
        </div>
      )}

      <style jsx>{`
        .email-sender {
          max-width: 500px;
          margin: 2rem auto;
          padding: 2rem;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        h2 {
          font-size: 1.5rem;
          margin-bottom: 1rem;
          color: #333;
        }

        .form-group {
          margin-bottom: 1rem;
        }

        label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: bold;
        }

        input, textarea, select {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1rem;
        }

        select {
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          background-size: 12px;
          padding-right: 2rem;
        }

        textarea {
          resize: vertical;
        }

        .bbackButton {
          width: 100%;
          padding: 0.75rem;
          background-color: #8a30e2;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        button {
          width: 100%;
          padding: 0.75rem;
          background-color: #4a90e2;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        button:hover {
          background-color: #357abd;
        }

        .alert {
          margin-top: 1rem;
          padding: 0.75rem;
          border-radius: 4px;
          font-weight: bold;
        }

        .success {
          background-color: #d4edda;
          color: #155724;
          border: 1px solid #c3e6cb;
        }

        .error {
          background-color: #f8d7da;
          color: #721c24;
          border: 1px solid #f5c6cb;
        }
      `}</style>
    </div>
  );
};

export default EmailSender;