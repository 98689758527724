import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router';
import { useCurrencyFormatter } from './currencyFormatter';
import '../styles/transfer.css';

const LocalTransferForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatCurrency, getUserBalance, error: currencyError, refetchUserData } = useCurrencyFormatter();
  const [balance, setBalance] = useState('');

  useEffect(() => {
    const fetchUserBalance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${global.baseUrl}users/balance`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || t('failedToFetchBalance'));
        }
        setBalance(parseFloat(data.balance) || 0);
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };
    fetchUserBalance();
  }, [t]);

  const navigateToDashBoard = () => {
    setTimeout(() => {
      navigate('/dashboard');
    }, 1000);
  };

  const [formData, setFormData] = useState({
    amount: '',
    beneficiaryAccountName: '',
    beneficiaryAccountNumber: '',
    bankName: '',
    bankAddress: '',
    swift: '',
    onlineBanking: '',
    note: ''
  });
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(formData.amount) > balance) {
      setError(`Insufficient balance.`);
      return;
    }
    setShowPopup(true);
    // In a real application, you'd handle the form submission here
  };

  if (currencyError) {
    return (
      <div>
        Error: {currencyError}. <button onClick={refetchUserData}>Retry</button>
      </div>
    );
  }

  return (
    <div className="form-container">
      <div className="form-header">
        <h2>{t('transfer')}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="amount-input-wrapper">
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder={t('amountPlaceholder')}
              className="form-input"
              min="0"
              step="0.01"
            />
            <span className={`formatted-amount ${formData.amount && parseFloat(formData.amount) > balance ? 'error-text' : ''}`}>
              {formData.amount ? formatCurrency(parseFloat(formData.amount)) : ''}
            </span>
          </div>
          {error && <div className="error-message">{error}</div>}
          <input
            type="text"
            name="beneficiaryAccountName"
            value={formData.beneficiaryAccountName}
            onChange={handleChange}
            placeholder={t('beneficiaryAccountName')}
            className="form-input"
          />
          <input
            type="number"
            name="beneficiaryAccountNumber"
            value={formData.beneficiaryAccountNumber}
            onChange={handleChange}
            placeholder={t('beneficiaryAccountNumber')}
            className="form-input"
          />
          <input
            type="text"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
            placeholder={t('bankName')}
            className="form-input"
          />
          <input
            type="text"
            name="bankAddress"
            value={formData.bankAddress}
            onChange={handleChange}
            placeholder={t('bankAddress')}
            className="form-input"
          />
          <input
            type="text"
            name="swift"
            value={formData.swift}
            onChange={handleChange}
            placeholder={t('swiftCode')}
            className="form-input"
            pattern="[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?"
          />
          <select
            name="onlineBanking"
            value={formData.onlineBanking}
            onChange={handleChange}
            className="form-input"
          >
            <option value="">{t('onlineBanking')}</option>
            {/* Add more options here */}
          </select>
          <input
            type="text"
            name="note"
            value={formData.note}
            onChange={handleChange}
            placeholder={t('note')}
            className="form-input"
          />
          <button type="submit" className="submit-button">
            {t('transfer')}
          </button>
        </div>
      </form>
      <button onClick={() => navigateToDashBoard()} className="back-button">
        {t('backToDashboard')}
      </button>

      {showPopup && (
        <div className="popup">
          <p className="popup-message">
            Unable to Transfer. <br />
            Request for Documentation to Activate Line of Credit
          </p>
          <button onClick={() => setShowPopup(false)} className="popup-close-button">
            <X size={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default LocalTransferForm;