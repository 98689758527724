import GoldSpinner from './GoldSpinner';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/ViewUser.css';

const ViewUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('Completed');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  useEffect(() => {
    fetchUserData();
  }, [userId]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${global.baseUrl}users/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setUserData(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAction = (action, transaction = null) => {
    switch (action) {
      case 'edit':
        navigate(`/admin/edit/${userId}`);
        break;
      case 'delete':
        setModalContent('deleteUser');
        setShowModal(true);
        break;
      case 'addBalance':
        setModalContent('addBalance');
        setShowModal(true);
        break;
      case 'removeBalance':
        setModalContent('removeBalance');
        setShowModal(true);
        break;
      case 'updateStatus':
        setModalContent('updateStatus');
        setSelectedTransaction(transaction);
        setStatus(transaction.status);
        setShowModal(true);
        break;
      default:
        return;
    }
  };

  const handleSubmit = async (action) => {
    const token = localStorage.getItem('token');
    let url, method, body;

    switch (action) {
      case 'deleteUser':
        url = `${global.baseUrl}users/deleteAccount/${userId}`;
        method = 'DELETE';
        break;
      case 'addBalance':
      case 'removeBalance':
        url = `${global.baseUrl}users/${action}`;
        method = 'POST';
        body = JSON.stringify({ userId: Number(userId), amount: Number(amount), status });
        break;
      case 'updateStatus':
        url = `${global.baseUrl}users/transactions/updateStatus`;
        method = 'POST';
        body = JSON.stringify({ transactionId: selectedTransaction.id, newStatus: status });
        break;
      default:
        return;
    }

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action}`);
      }

      // Refresh user data after successful action
      await fetchUserData();
      setShowModal(false);
      setAmount('');
      setStatus('Completed');
      setSelectedTransaction(null);
    } catch (err) {
      setError(err.message);
    }
  };


  const handleApproveUser = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${global.baseUrl}users/approve/user`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: userData.user.email })
      });

      if (!response.ok) {
        throw new Error('Failed to approve user');
      }

      // Refresh user data after successful approval
      await fetchUserData();
    } catch (err) {
      setError(err.message);
    }
  };

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  if (!userData) {
    return <GoldSpinner />;
  }

  const { user, transactions } = userData;
  let approved = user.approved ? 'Approved' : 'Not Approved'
  return (
    <div className='view-user-cont'>
      <h1>User Details</h1>
      <div className='user-actions'>
        <button onClick={() => handleAction('edit')}>Edit User</button>
        <button onClick={() => handleAction('delete')}>Delete User</button>
        <button onClick={() => handleAction('addBalance')}>Add Balance</button>
        <button onClick={() => handleAction('removeBalance')}>Remove Balance</button>
        {!user.approved && (
          <button onClick={handleApproveUser}>Approve User</button>
        )}
      </div>
      <div className='transactions'>
        <h2>Transaction History</h2>
        <table className='transaction-table'>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
              <th>Type</th>
              <th>Reference</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => {
              const date = new Date(transaction.dateTime);
              return (
                <tr key={transaction.id}>
                  <td>${parseFloat(transaction.amount).toFixed(2)}</td>
                  <td>{date.toLocaleDateString()}</td>
                  <td>{date.toLocaleTimeString()}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.type}</td>
                  <td>{transaction.reference}</td>
                  <td>
                    <button onClick={() => handleAction('updateStatus', transaction)}>
                      Update Status
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            {modalContent === 'deleteUser' ? (
              <>
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this user?</p>
                <div className='modal-actions'>
                  <button onClick={() => handleSubmit('deleteUser')}>Yes</button>
                  <button onClick={() => setShowModal(false)}>No</button>
                </div>
              </>
            ) : modalContent === 'updateStatus' ? (
              <>
                <h2>Update Transaction Status</h2>
                <p>Current Status: {selectedTransaction.status}</p>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
                <div className='modal-actions'>
                  <button onClick={() => handleSubmit('updateStatus')}>Update</button>
                  <button onClick={() => setShowModal(false)}>Cancel</button>
                </div>
              </>
            ) : (
              <>
                <h2>{modalContent === 'addBalance' ? 'Add' : 'Remove'} Balance</h2>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter amount"
                />
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
                <div className='modal-actions'>
                  <button onClick={() => handleSubmit(modalContent)}>Confirm</button>
                  <button onClick={() => setShowModal(false)}>Cancel</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUser;