import '../styles/login.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha
} from "react-simple-captcha";

// Captcha Modal
const CaptchaModal = ({ onCaptchaSuccess }) => {
  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  const doSubmit = () => {
    let user_captcha = document.getElementById("user_captcha_input").value;
    if (validateCaptcha(user_captcha)) {
      alert("Captcha Matched");
      onCaptchaSuccess();
      loadCaptchaEnginge(5);
      document.getElementById("user_captcha_input").value = "";
    } else {
      alert("Captcha Does Not Match");
      document.getElementById("user_captcha_input").value = "";
    }
  };

  return (
    <Modal show={true} centered>
      <Modal.Header>
        <Modal.Title>Captcha Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className="col mt-3">
            <LoadCanvasTemplate />
          </div>
          <div className="col mt-3">
            <input
              placeholder="Enter Captcha"
              id="user_captcha_input"
              name="user_captcha_input"
              type="text"
              className="form-control"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={doSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Error Modal
const ErrorModal = ({ show, message, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Login = () => {
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();

  const handleCaptchaSuccess = () => {
    setIsCaptchaValidated(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${global.baseUrl}users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: accountNumber, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      // Login successful
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      if (data.user.admin) {
        navigate('/admin'); // Redirect to dashboard or appropriate page

      } else {
        navigate('/dashboard');
      }// Redirect to dashboard or appropriate page
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'An error occurred during login');
      setShowErrorModal(true);  // Show error modal
    }
  };

  const handleEnrollNow = () => {
    navigate('/signup');
  };

  const closeModal = () => {
    setShowErrorModal(false);
  };

  if (!isCaptchaValidated) {
    return <CaptchaModal onCaptchaSuccess={handleCaptchaSuccess} />;
  }

  return (
    <div className="signin-container">
      <h2 className="signin-title">Sign In</h2>
      <p className="signin-description">
        Access the Tai Sang Bank online banking panel using your Account number and password.
      </p>
      {error && <p className="error-message">{error}</p>}
      <form className="signin-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Account Number or User name"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="signin-button">
          Log in
        </button>
      </form>
      <div className="reset-password">
        <a href="#">Reset password</a>
      </div>
      <div className="enroll-now">
        No account? <a href="#" onClick={handleEnrollNow}>Enroll now</a>
      </div>
      <ErrorModal
        show={showErrorModal}
        message={error}
        onClose={closeModal}
      />
    </div>
  );
};

export default Login;
