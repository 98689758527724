import authMiddleware from './authMiddleware';
import React, { useState, useEffect, useRef } from 'react';
import { FaBell } from 'react-icons/fa';
import '../styles/NotificationDropdown.css';

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [error, setError] = useState(null);
  
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${global.baseUrl}users/notification`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch notifications');
        }
        setNotifications(data.notifications);
        setUnreadCount(data.notifications.filter(notif => !notif.readStatus).length);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setError(error.message || 'Error fetching notifications');
      }
    };

    fetchNotifications();
  }, []);

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleMouseEnter = () => {
    if (isOpen) {
      dropdownRef.current.style.display = 'block';
    }
  };

  const handleMouseLeave = () => {
    if (isOpen) {
      dropdownRef.current.style.display = 'none';
    }
  };

  const markAsRead = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${global.baseUrl}users/notification/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }
      setNotifications(notifications.map(notif =>
        notif.id === id ? { ...notif, readStatus: true } : notif
      ));
      setUnreadCount(notifications.filter(notif => !notif.readStatus).length - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div 
      className={`notification-dropdown ${isOpen ? 'open' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="notification-icon" onClick={toggleDropdown}>
        <FaBell />
        {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
      </div>
      <div 
        className="dropdown-content"
        ref={dropdownRef}
      >
        <h3>Notifications</h3>
        {notifications.length > 0 ? (
          <ul>
            {notifications.map((notif) => (
              <li key={notif.id} className={notif.readStatus ? 'read' : 'unread'}>
                <p>{notif.message}</p>
                <small>{new Date(notif.createdAt).toLocaleString()}</small>
                {!notif.readStatus && (
                  <button onClick={() => markAsRead(notif.id)}>Mark as read</button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No notifications</p>
        )}
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default authMiddleware(NotificationDropdown)